import { gql } from 'graphql-tag'

export const GET_COMPANY_LEGAL_REPORTS_QUERY = gql`
  query GET_COMPANY_LEGAL_REPORTS_QUERY {
    getCompanyLegalReports {
      id
      year
      type
      createdAt
      releasedAt
      filters {
        member
        operator
        values
      }
    }
  }
`
