import { LegalReportType, type LegalReport } from '~/types/legalReports'
import { GET_COMPANY_LEGAL_REPORTS_QUERY } from '~/gql/legalReports/getCompanyLegalReportsQuery'
import { replaceRouteQueryParam } from '~/services/router'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { hasSameFilterValues } from '~/services/filters'
import { getMainBdeseFilter } from '~/services/bdese'
import type { Filter } from '~/types/analytics'

interface State {
  availableLegalReports: undefined | LegalReport[]
  selectedYear: null | undefined | string
  selectedLegalReportId: null | undefined | string
}
export const useLegalReportsStore = defineStore('legalReports', {
  state: (): State => ({
    availableLegalReports: undefined,
    selectedYear: undefined,
    selectedLegalReportId: undefined
  }),
  getters: {
    selectedYearLegalReports: (state: State) => {
      return state.availableLegalReports!.filter(
        legalReports => legalReports.year.toString() === state.selectedYear
      )
    }
  },
  actions: {
    async setSelectedYear(year: string | null, shouldModifyUrlParams = true) {
      if (year) {
        this.selectedYear = year
      } else if (this.availableLegalReports!.length > 0) {
        this.selectedYear = this.availableLegalReports![0]!.year.toString()
      } else {
        this.selectedYear = new Date().getFullYear().toString()
      }

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.LEGAL_REPORT_YEAR, this.selectedYear]
        ]))
    },
    async setSelectedLegalReportId(
      id: string | null,
      shouldModifyUrlParams = true
    ) {
      this.selectedLegalReportId = id

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [
            QUERY_PARAMETERS.LEGAL_REPORT_ID,
            this.selectedLegalReportId || undefined
          ]
        ]))
    },
    async getAvailableLegalReports() {
      const { $apiGqlClient } = useNuxtApp()

      const {
        data: { getCompanyLegalReports }
      }: { data: { getCompanyLegalReports: LegalReport[] } } =
        await $apiGqlClient.query({
          query: GET_COMPANY_LEGAL_REPORTS_QUERY
        })

      this.availableLegalReports = getCompanyLegalReports
    },
    updateAvailableLegalReports(legalReport: LegalReport) {
      const legalReportIndex = this.availableLegalReports!.findIndex(
        report => report.id === legalReport.id
      )

      if (legalReportIndex! < 0) {
        this.availableLegalReports = [
          ...this.availableLegalReports!,
          legalReport
        ]
      } else {
        this.availableLegalReports = this.availableLegalReports!.toSpliced(
          legalReportIndex,
          1,
          legalReport
        )
      }
    },
    getLegalReport(
      year: number,
      type: LegalReportType,
      mainFilter: Filter | undefined
    ) {
      return this.availableLegalReports!.find(
        legalReport =>
          legalReport.year === year &&
          legalReport.type === type &&
          hasSameFilterValues(
            getMainBdeseFilter(legalReport.filters)?.values,
            mainFilter?.values
          )
      )
    }
  }
})
